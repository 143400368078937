<template>
    <b-card
      
    >  
        <!-- Card Header -->
        
           <h4 class="mb-2"> More Information with Relation to CV</h4>

           <b-row>
            <b-col md="3">
                <h5 class="mb-2"> CV Read Time</h5>
                <p> 5 min</p>
            </b-col>
            <b-col md="3">
                <h5 class="mb-2"> is CV Readable</h5>
                <p> YES</p>
            </b-col>
            <b-col md="3">
                <h5 class="mb-2"> CV ATS Score Percentile</h5>
                <p> 80%</p>
            </b-col>
            <b-col md="3">
                <h5 class="mb-2"> Date Format Consistency</h5>
                <p> Consistent</p>
            </b-col>
           </b-row> 
        
    </b-card>
</template>

<script>
import { BCard, BRow, BCol} from 'bootstrap-vue'
export default {
    data() {
      return {
        
      }
    },
    components: {
        BCard, BRow, BCol
    },
    directives: {
    
    },
    computed: {
        
    },
    watch: {
        
    },
    created() {

    },
    methods: {
        
    },
    updated: function () {
        
    },
    mounted() {
    
    }
}
</script>
<style lang="scss">

</style>