<template>
    <b-card>
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-2">Certifications</h4>
        </div>
        <b-list-group>
            <b-list-group-item >CLSA - PegaLead System Architect 8.5</b-list-group-item>
            <b-list-group-item >CSSA - PegaCertified Senior System Architect 7.x</b-list-group-item>
            <b-list-group-item >CSA - Pega Certified System Architect 7.x</b-list-group-item>
            <b-list-group-item >CDH - Pega Decisioning Consultant Certification</b-list-group-item>
        </b-list-group>
    </b-card>
  </template>

  <script>
  import { BListGroup, BListGroupItem, BCard } from 'bootstrap-vue'
  
  export default {
    components: {
      BListGroup,
      BListGroupItem,
      BCard
    },
  }
  </script>