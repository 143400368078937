<template>
    <b-card>
            <div>
            <h4 class="mb-2">Action Verbs</h4>
            <h5 class="mb-2 text-info">Action Verbs used in resume is very good with a score of 70%</h5>
            </div>    
            <div class="demo-inline-spacing" >
                <b-badge v-for="verb in verbs" :key="verb.index" pill variant="light-success">{{verb}}</b-badge>
            </div>
            
        
    </b-card>    
</template>

<script>
import { BCard, BBadge} from 'bootstrap-vue'
export default {
    data() {
      return {
        verbs: ["Rendering", "Worked", "Managing", "Taking care", "Involving", "Develop", "Coordinating", "Suggesting", "Coming up", "Implementing", "Testing", "Handing over", "Supporting", "Fixing", "Monitoring", "Attended", "Analyzing", "Estimating", "Created", "Extensively used", "Interacted", "Designed"]
      }
    },
    components: {
        BBadge, BCard
    },
    directives: {
    
    },
    computed: {
        
    },
    watch: {
        
    },
    created() {

    },
    methods: {
        
    },
    updated: function () {
        
    },
    mounted() {
    
    }
}
</script>
<style lang="scss">

</style>