<template>
    <div>
        <b-row class="match-height">
            <b-col md="4">
                <candidate-info/>
            </b-col>
            <b-col md="8">
                <candidate-rating :data="data.goalOverview"/>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <candidate-detailed-scores :data="data.earningsChart"/>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <candidate-skills :data="data.earningsChart"/>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <candidate-detail-analysis/>
            </b-col>
        </b-row>
        <b-row class="match-height">
            <b-col cols="6">
                <candidate-lang-proficiency/>
            </b-col>
            <b-col cols="6">
                <candidate-certifications/>
            </b-col>
        </b-row>  
        <b-row>
            <b-col cols="12">
                <candidate-action-verbs-vue/>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <candidate-summary/>
            </b-col>
        </b-row>              
    </div>    
</template>

<script>
import { BRow, BCol, BCard, BCardHeader,  } from 'bootstrap-vue'


import CandidateInfo from './CandidateInfo.vue'
import CandidateRating from './CandidateRating.vue'
import CandidateDetailedScores from './CandidateDetailedScores.vue'
import CandidateSkills from './CandidateSkills.vue'
import CandidateDetailAnalysis from './CandidateDetailAnalysis.vue'
import CandidateCertifications from './CandidateCertifications.vue'
import CandidateLangProficiency from './CandidateLangProficiency.vue'
import CandidateActionVerbsVue from './CandidateActionVerbs.vue'
import CandidateSummary from './CandidateSummary.vue'



export default {
    data() {
      return {
        data: {
            goalOverview: {
                series: [83],
            },
            earningsChart: {
                series: [53, 16, 31],
            },
        }
      }
    },
    components: {
        BRow, 
        BCol, 
        BCard, 
        
        BCardHeader,
        CandidateInfo,
        CandidateRating,
        CandidateDetailedScores,
        CandidateSkills,
        CandidateDetailAnalysis,
        CandidateCertifications,
        CandidateLangProficiency,
        CandidateSummary,
        CandidateActionVerbsVue

    },
    directives: {
        
    
    },
    computed: {
        
    },
    watch: {
        
    },
    created() {

    },
    methods: {
        
    },
    updated: function () {
        
    },
    mounted() {
    
    }
}
</script>
<style lang="scss">

</style>