<template>
    <b-row>
        <b-col md="6">
            <b-card
                v-if="data"
                no-body
            >
                <b-card-header>
                    <h4 class="mb-0">
                        Relevancy Score
                    </h4>
                    <b-card-text class="font-medium-5 mb-0">
                        <feather-icon
                            icon="HelpCircleIcon"
                            size="21"
                            class="text-muted cursor-pointer"
                        />
                    </b-card-text>
                </b-card-header>
            
                <!-- apex chart -->
                <vue-apex-charts
                    type="radialBar"
                    height="220"
                    class="my-2"
                    :options="goalOverviewRadialBar"
                    :series="data.series"
                />
            </b-card>
        </b-col>
        <b-col md="6" >
            <b-card
                v-if="data"
                no-body
                style="height: 290px"
            >
                <b-card-header>
                    <h4 class="mb-2">
                        Final Recommendation 
                    </h4>
                    <h5 class="text-primary"> Proceed with Interview</h5>
                    <b-card-text class="font-medium-5 mb-0">
                        <feather-icon
                            icon="HelpCircleIcon"
                            size="21"
                            class="text-muted cursor-pointer"
                        />
                    </b-card-text>
                </b-card-header>
                
          <!-- chart -->
                    <vue-apex-charts
                        height="120"
                        :options="earningsChart.chartOptions"
                        :series="[85, 15]"
                    />
                    <h5 class="text-center">Job Relevancy</h5>
                
            
                <!-- apex chart -->
                
            </b-card>
        </b-col>
    </b-row>
    
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BRow, BCol, BCardText,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  
  const $strokeColor = '#ebe9f1'
  const $textHeadingColor = '#5e5873'
  const $goalStrokeColor2 = '#51e5a8'
  const $earningsStrokeColor2 = '#28c76f66'
  const $earningsStrokeColor3 = '#28c76f33'
  export default {
    components: {
      VueApexCharts,
      BCard,
      BCardHeader,
      BRow,
      BCardText,
      BCol,
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        goalOverviewRadialBar: {
          chart: {
            height: 220,
            type: 'radialBar',
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: [$goalStrokeColor2],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: $strokeColor,
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: $textHeadingColor,
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 15,
            },
          },
        },
        earningsChart: {
          chartOptions: {
            chart: {
              type: 'donut',
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: { show: false },
            comparedResult: [2, -3, 8],
            labels: ['Match', 'Unmatch'],
            stroke: { width: 0 },
            colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
            grid: {
              padding: {
                right: -20,
                bottom: -8,
                left: -20,
              },
            },
            plotOptions: {
              pie: {
                startAngle: -10,
                donut: {
                  labels: {
                    show: true,
                    name: {
                      offsetY: 15,
                    },
                    value: {
                      offsetY: -15,
                      formatter(val) {
                        // eslint-disable-next-line radix
                        return `${parseInt(val)}%`
                      },
                    },
                    total: {
                      show: true,
                      offsetY: 15,
                      label: 'App',
                      formatter() {
                        return '85%'
                      },
                    },
                  },
                },
              },
            },
            responsive: [
              {
                breakpoint: 1325,
                options: {
                  chart: {
                    height: 100,
                  },
                },
              },
              {
                breakpoint: 1200,
                options: {
                  chart: {
                    height: 120,
                  },
                },
              },
              {
                breakpoint: 1045,
                options: {
                  chart: {
                    height: 100,
                  },
                },
              },
              {
                breakpoint: 992,
                options: {
                  chart: {
                    height: 120,
                  },
                },
              },
            ],
          },
        },
      }
    },
  }
  </script>
  