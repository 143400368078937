<template>
    <b-card>
        <h3 class="mb-1">Nagaraju Pulusu</h3>
        <p class="ml-auto"> nagarajupulusu735@gmail.com</p>
        <p class="ml-auto"> 9000342104</p>
        <p class="ml-auto"> 11 Years 5 months</p>
        <p class="ml-auto"> Qualification : MCA </p>
        <p class="ml-auto"> CTC: 25 Lacs Expected: 35 Lacs </p>
        <p class="ml-auto"> 90 Days </p>
    </b-card>
</template>

<script>
import { BCard  } from 'bootstrap-vue'
export default {
    data() {
      return {
        
      }
    },
    components: {
        BCard
    },
    directives: {
    
    },
    computed: {
        
    },
    watch: {
        
    },
    created() {

    },
    methods: {
        
    },
    updated: function () {
        
    },
    mounted() {
    
    }
}
</script>
<style lang="scss">

</style>