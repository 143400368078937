<template>
    <b-list-group>
      <b-list-group-item >Awesome link</b-list-group-item>
      <b-list-group-item >Link with active state</b-list-group-item>
      <b-list-group-item >Action links are easy</b-list-group-item>
      <b-list-group-item >Action links are fast</b-list-group-item>
      <b-list-group-item >Disabled link</b-list-group-item>
    </b-list-group>
  </template>
  
  <script>
  import { BListGroup, BListGroupItem } from 'bootstrap-vue'
  
  export default {
    components: {
      BListGroup,
      BListGroupItem,
    },
  }
  </script>