<template>
    <b-card >
        
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-2"> Language Proficiency</h4>
        </div>  
  
      <!-- progress bar -->
      <b-row class="avg-sessions pt-50">
        <b-col
          cols="12"
          class="mb-2"
        >
          <b-card-text class="mb-50">
            English: Fluent
          </b-card-text>
          <b-progress
            value="75"
            max="100"
            height="6px"
            variant="success"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          <b-card-text class="mb-50">
            Tamil: Native
          </b-card-text>
          <b-progress
            value="90"
            max="100"
            height="6px"
            variant="success"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          <b-card-text class="mb-50">
            Telgu: Native
          </b-card-text>
          <b-progress
            value="90"
            max="100"
            height="6px"
            variant="success"
          />
        </b-col>
        
      </b-row>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BButton, BDropdown, BDropdownItem, BProgress, BCardText,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import Ripple from 'vue-ripple-directive'
  import { $themeColors } from '@themeConfig'
  import { kFormatter } from '@core/utils/filter'
  
  export default {
    components: {
      VueApexCharts,
      BCard,
      BRow,
      BCol,
      BButton,
      BCardText,
      BDropdown,
      BDropdownItem,
      BProgress,
    },
    directives: {
      Ripple,
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        salesBar: {
          chartOptions: {
            chart: {
              sparkline: { enabled: true },
              toolbar: { show: false },
            },
            grid: {
              show: false,
              padding: {
                left: 0,
                right: 0,
              },
            },
            states: {
              hover: {
                filter: 'none',
              },
            },
            colors: [
              '#ebf0f7',
              '#ebf0f7',
              $themeColors.primary,
              '#ebf0f7',
              '#ebf0f7',
              '#ebf0f7',
            ],
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
                endingShape: 'rounded',
              },
            },
            tooltip: {
              x: { show: false },
            },
            xaxis: {
              type: 'numeric',
            },
          },
        },
      }
    },
    methods: {
      kFormatter,
    },
  }
  </script>
  