<template>
    <div>
        <b-row class="match-height">
            <b-col md="12">
                <b-card
                    :border-variant="primary"
                    title="Summary/Objective Analysis"
                    
                >
                <b-card-text class="text-primary">
                    Present and aligns with the job description
                </b-card-text>
                </b-card>
            </b-col>
            <b-col md="6">
                <b-card
                    :border-variant="primary"
                    title="Experience Analysis"
                    
                >
                <b-card-text >
                    The candidate has 11 years of experience in PEGA PRPC, demonstrating expertise in various Pega projects and roles. They have experience in requirement analysis, design, development, testing, and implementation of Pega applications. They have worked with clients across different industries and have a strong understanding of Pega best practices.
                </b-card-text>
                </b-card>
            </b-col>
            <b-col md="6">
                <b-card
                    :border-variant="primary"
                    title="Technical Analysis"
                    
                >
                <b-card-text >
                    The candidate possesses a strong set of technical skills relevant to the job description, including Pega PRPC, various Pega studios, and experience with design and development tools. They have knowledge of application architecture, data modeling, UI rules, and security features.
                </b-card-text>
                </b-card>
            </b-col>
            <b-col md="6">
                <b-card
                    :border-variant="primary"
                    title="Sentiment Analysis"
                    
                >
                <b-card-text >
                    The CV conveys a positive and enthusiastic tone, showcasing the candidate's passion for Pega and their accomplishments.
                </b-card-text>
                </b-card>
            </b-col>
            <b-col md="6">
                <b-card
                    :border-variant="primary"
                    title="Motivation and Cultural Fit Analysis"
                    
                >
                <b-card-text >
                    The candidate's motivation and cultural fit seem to align well with the job description. Their experience and skills demonstrate a commitment to Pega and a willingness to learn and adapt.
                </b-card-text>
                </b-card>
            </b-col>
            <b-col md="6">
                <b-card
                    :border-variant="primary"
                    title="Leadership Experience"
                    
                >
                <b-card-text >
                    The candidate has leadership experience, as they mention leading a team in their current role as a Senior Applications Consultant.
                </b-card-text>
                </b-card>
            </b-col>
            <b-col md="6">
                <b-card
                    :border-variant="primary"
                    title="Project Management Skill"
                    
                >
                <b-card-text >
                    The candidate's experience indicates exposure to project management methodologies through their involvement in Agile/Scrum projects.
                </b-card-text>
                </b-card>
            </b-col>
            
        </b-row>
    </div>    
    
</template>
<script>
import {
    BCard, BRow, BCol, BCardTitle, BCardText,
  } from 'bootstrap-vue'
export default {
    data() {
      return {
        solidColor: [
        { bg: 'primary', title: 'Primary card title' },
        { bg: 'secondary', title: 'Secondary card title' },
        { bg: 'success', title: 'Success card title' },
        { bg: 'danger', title: 'Danger card title' },
        { bg: 'warning', title: 'Warning card title' },
        { bg: 'info', title: 'Info card title' },
      ],
      }
    },
    components: {
        BCard, BRow, BCol, BCardTitle, BCardText
    },
    directives: {
    
    },
    computed: {
        
    },
    watch: {
        
    },
    created() {

    },
    methods: {
        
    },
    updated: function () {
        
    },
    mounted() {
    
    }
}
</script>
<style lang="scss">

</style>